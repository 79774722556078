<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <export-excel
                class="btn excel_btn"
                :data="activeItem"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                worksheet="Отчет-врачи"
                :name="`Отчет-врач ${doctor_name}.xls`"
              >
                <el-button icon="el-icon-document-delete"> Excel </el-button>
              </export-excel>
              <el-button class="pb-2" @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="modal-doctoe-info" v-loading="loadingData">
      <el-row :gutter="10" class="credit_row" v-if="['bonus','secondary_bonus'].includes(activeFilterIndex)">
        <el-col :span="4">
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>
            {{ doctor_name }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>
            {{
              bonus && secondary_bonus
                ? bonus.total_count + secondary_bonus.total_count
                : 0
            }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="font__W">{{ $t("message.cash_amount") }}</div>
          <div>{{ bonus ? bonus.total_bonus_amount : 0 | formatMoney }}</div>
        </el-col>
        <el-col :span="3">
          <div class="font__W">{{ $t("message.bonus_amount_0") }}</div>
          <div>
            {{
              secondary_bonus
                ? secondary_bonus.total_bonus_amount
                : 0 | formatMoney
            }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="font__W">{{ $t("message.totals") }}</div>
          <div>
            {{
              bonus && secondary_bonus
                ? bonus.total_bonus_amount + secondary_bonus.total_bonus_amount
                : 0 | formatMoney
            }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="font__W">{{ $t("message.paid") }}</div>
          <div>
            {{
              bonus && secondary_bonus
                ? bonus.total_paid_bonus + secondary_bonus.total_paid_bonus
                : 0 | formatMoney
            }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="font__W">{{ $t("message.remainder") }}</div>
          <div>
            {{
              bonus && secondary_bonus
                ? bonus.total_reaminder_bonus +
                  secondary_bonus.total_reaminder_bonus
                : 0 | formatMoney
            }}
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="10" class="credit_row" v-if="activeFilterIndex === 'hospital_bonus'">
        <el-col :span="4">
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>
            {{ doctor_name }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>
            {{
              hospital_bonus ? hospital_bonus.total_count : 0
            }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.cash_amount") }}</div>
          <div>{{ hospital_bonus ? hospital_bonus.total_bonus_amount : 0 | formatMoney }}</div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.totals") }}</div>
          <div>
            {{ hospital_bonus ? hospital_bonus.total_bonus_amount : 0 | formatMoney }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.paid") }}</div>
          <div>
            {{ hospital_bonus ? hospital_bonus.total_paid_bonus : 0 | formatMoney }}
          </div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.remainder") }}</div>
          <div>
            {{ hospital_bonus ? hospital_bonus.total_reaminder_bonus : 0 | formatMoney }}
          </div>
        </el-col>
      </el-row>

      <div class="text-center float-left sorddata my-3">
        <el-date-picker
          size="mini"
          v-model="filterForm.start_date"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.end_date"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker>
      </div>
      <div class="float-right m-3 d-flex" v-loading="loadingData">
        <div class="mr-3 credit_row p-2" style="color: green">
          {{ selected_bonus_amount | formatNumber }}
        </div>
        <el-button
          type="success"
          @click="openDialog()"
          :disabled="!checkedOrders.length"
        >
          {{ $t("message.pay") }}
        </el-button>
      </div>
      <div class="filter-wrap" style="margin-top: 20px">
        <button
          @click="statisticFilter('bonus')"
          class="filter-wrap__btn"
          :class="{ active: 'bonus' === activeFilterIndex }"
        >
          {{ $t(`message.services`) }}
        </button>
        <button
          @click="statisticFilter('secondary_bonus')"
          class="filter-wrap__btn"
          :class="{ active: 'secondary_bonus' === activeFilterIndex }"
        >
          {{ $t(`message.ambulatory`) }} {{ $t(`message.bonus`) }}
        </button>
        <button
            @click="statisticFilter('hospital_bonus')"
            class="filter-wrap__btn"
            :class="{ active: 'hospital_bonus' === activeFilterIndex }"
        >
          {{ $t(`message.stationary`) }}
        </button>
      </div>
      <table
        class="table table-hover table-bordered mt-3 table__cerditne__forma"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>

        <el-dialog
          :title="$t('message.pay')"
          :visible.sync="dialogVisible"
          width="30%"
          :append-to-body="true"
        >
          <el-select
            v-model="payment_type_id"
            filterable
            :placeholder="$t('message.payment_type')"
          >
            <el-option
              v-for="item in paymentTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="success"
            style="margin-left: 80px; padding: 12px !important"
            @click="paidBonus"
            :disabled="!payment_type_id"
          >
            {{ $t("message.pay") }}
          </el-button>
        </el-dialog>
        <template v-if="['bonus', 'secondary_bonus'].includes(activeFilterIndex)">
          <thead>
          <tr>
            <th>
              <input
                  type="checkbox"
                  id="mainCheckbox"
                  @change="allchecked($event)"
              />
            </th>
            <th>id</th>
            <th scope="col">{{ $t("message.type") }}</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <th scope="col">{{ $t("message.bonus_date") }}</th>
            <th scope="col">{{ $t("message.service") }}</th>
            <th scope="col">{{ $t("message.amount") }}</th>
            <th scope="col">{{ $t("message.bonus") }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in activeItem"
              :key="index"
              :style="colorPicker(item)"
          >
            <td>
              <input
                  type="checkbox"
                  ref="checkboxFor"
                  :value="item"
                  :disabled="item.bonus_amount == item.paid_amount"
                  @change="addPayment(item, $event)"
              />
            </td>
            <td>{{ id(item) }}</td>
            <td>{{ type(item) }}</td>
            <td>
              {{ patientName(item) }}
            </td>
            <td>{{ bonusItemEntity(item).created_at }}</td>
            <td>{{ item.created_at }}</td>
            <td class="service" v-if="bonusItemEntity(item) && bonusItemEntity(item).service">
                {{ bonusItemEntity(item).service.name }}
            </td>
            <td v-else>
              {{ " - " }}
            </td>
            <td>
              <span>{{
                  item
                      ? bonusItemEntity(item).total_price
                      : 0 | formatMoney
                }}</span>
            </td>
            <td>
              <span class="act_color">{{
                  item ? item.bonus_amount : 0 | formatMoney
                }}</span>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-if="['hospital_bonus'].includes(activeFilterIndex)">
          <thead>
          <tr>
            <th>
              <input
                  type="checkbox"
                  id="mainCheckbox"
                  @change="allchecked($event)"
              />
            </th>
            <th>id</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <th scope="col">{{ $t("message.bonus_date") }}</th>
            <th scope="col">{{ $t("message.amount") }}</th>
            <th scope="col">{{ $t("message.bonus") }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in activeItem"
              :key="index"
              :style="colorPicker(item)"
          >
            <td>
              <input
                  type="checkbox"
                  ref="checkboxFor"
                  :value="item"
                  :disabled="item.bonus_amount == item.paid_amount"
                  @change="addPayment(item, $event)"
              />
            </td>
            <td>{{ item ? (item.patient_history ? item.patient_history.id : "") : "" }}</td>
            <td>
              {{
                item.patient_history && item.patient_history.hospitalPatient
                    ? item.patient_history.hospitalPatient.surname +
                    " " +
                    item.patient_history.hospitalPatient.first_name
                    : ""
              }}
            </td>
            <td>{{ item.patient_history ? item.patient_history.created_at : "" }}</td>
            <td>{{ item.created_at }}</td>
            <td>
              <span>{{
                  item
                      ? item.patient_history
                          ? item.patient_history.patientBalance.total_amount
                          : 0
                      : 0 | formatMoney
                }}</span>
            </td>
            <td>
              <span class="act_color">{{
                  item ? item.bonus_amount : 0 | formatMoney
                }}</span>
            </td>
          </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import form from "@/utils/mixins/form";
import {i18n} from "@/utils/i18n";
export default {
  mixins: [form],
  props: ["selected"],
  data() {
    return {
      filterForm: {
        start_date: "",
        end_date: "",
      },
      loadingData: false,
      excel_fields: {},
      payment_type_id: null,
      dialogItem: null,
      page: 1,
      doctor_name: "",
      per_page: 10,
      dialogVisible: false,
      checkedOrders: [],
      activeItem: null,
      activeFilterIndex: "bonus",
    };
  },
  computed: {
    ...mapGetters({
      bonus: "doctorsReport/bonus",
      hospital_bonus: "doctorsReport/hospital_bonus",
      secondary_bonus: "doctorsReport/secondary_bonus",
      model: "doctorsReport/model",
      reports: "doctorsReport/reports",
      columns: "doctorsReport/columns",
      paymentTypes: "paymentTypes/list",
      pagination: "doctorsReport/show_pagination",
    }),
    paid_bonus: function () {
      let orders = this.form;
      let prices = 0;
      if (orders) {
        for (let i = 0; i < orders.length; i++) {
          const element = orders[i];

          prices += parseFloat(element.paid_amount);
        }
      }
      return prices;
    },
    selected_bonus_amount: function () {
      let orders = this.checkedOrders;
      let prices = 0;
      if (orders) {
        for (let i = 0; i < orders.length; i++) {
          const element = orders[i];

          prices += parseFloat(element.bonus_amount);
        }
      }
      return prices;
    },
    total_bonus: function () {
      let orders = this.form;
      let prices = 0;
      if (orders) {
        for (let i = 0; i < orders.length; i++) {
          const element = orders[i];

          prices += parseFloat(element.bonus_amount);
        }
      }
      return prices;
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();
    this.paymentTypesList();
  },
  watch: {
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        await this.showReport();
      },
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        await this.showReport();
      },
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
    filterForm: {
      handler: function (newVal, oldVal) {
        this.showReport();
      },
      deep: true,
    },
    model: {
      handler: function (newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updatePagination: "doctorsReport/updateShowPagination",
      showModel: "doctorsReport/show",
      paymentTypesList: "paymentTypes/index",
      massPaid: "paymentBonus/massPaid",
    }),
    allchecked(event) {
      for (var i = 0; i < this.$refs["checkboxFor"].length; i++) {
        if (this.activeItem[i].bonus_amount != this.activeItem[i].paid_amount) {
          this.$refs["checkboxFor"][i].checked =
            document.getElementById("mainCheckbox").checked;
        }
      }
      if (document.getElementById("mainCheckbox").checked == true) {
        for (var j = 0; j < this.activeItem.length; j++) {
          if (this.$refs["checkboxFor"][j].checked) {
            this.checkedOrders.push(this.activeItem[j]);
          }
        }
      } else {
        this.checkedOrders = [];
      }
    },
    statisticFilter(index) {
      if (index == "bonus") {
        this.activeItem = this.bonus.data.doctors;
      }
      if (index == "secondary_bonus") {
        this.activeItem = this.secondary_bonus.data.doctors;
      }
      if (index == "hospital_bonus") {
        this.activeItem = this.hospital_bonus.data.doctors;
      }
      this.checkedOrders = [];
      this.activeFilterIndex = index;
    },
    colorPicker(item) {
      if (item !== null && item.bonus_amount == item.paid_amount) {
        return "background-color: rgb(171 255 168 / 62%)";
      } else {
        return "background-color: #ffffff";
      }
    },
    openDialog() {
      this.payment_type_id = null;
      this.dialogVisible = true;
    },
    paidBonus() {
      let bonus = ['bonus','hospital_bonus'].includes(this.activeFilterIndex) ? 'bonus' : 'secondary_bonus';
      const query = {
        items: this.checkedOrders,
        type: bonus,
        bonusable_type: this.selected.bonusable_type,
        bonusable_id: this.selected.bonusable_id,
        payment_type_id: this.payment_type_id,
      };
      this.massPaid(query).then((res) => {
        this.payment_type_id = null;
        this.checkedOrders = [];
        this.dialogVisible = false;
        this.showReport();
      });
    },
    addPayment(item, event) {
      if (event.srcElement.checked === true) {
        this.checkedOrders.push(item);
      }
      if (event.srcElement.checked === false) {
        const index = this.checkedOrders.indexOf(item);
        if (index > -1) {
          this.checkedOrders.splice(index, 1);
        }
      }
    },
    showReport() {
      this.loadingData = true;
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        id: this.selected.bonusable_id,
        start_date: this.filterForm.start_date,
        end_date: this.filterForm.end_date,
      };
      this.showModel(query)
        .then((res) => {
          this.statisticFilter("bonus");
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },

    afterOpen() {
      this.loadingData = true;
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        id: this.selected.bonusable_id,
        start_date: this.filterForm.start_date,
        end_date: this.filterForm.end_date,
      };
      this.doctor_name = this.selected.bonusable
        ? this.selected.bonusable.surname + " " + this.selected.bonusable.name
        : "";
      this.showModel(query)
        .then((res) => {
          this.statisticFilter("bonus");
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    controlExcelData() {
      this.excel_fields = {
        id: {
          field: "order_service",
          callback: (value) => {
            return value.order_id;
          },
        },
        "И.Ф.О": {
          field: "order_service",
          callback: (value) => {
            return value.order ?
                (value.order.patient ?
                    (value.order.patient.surname + " " + value.order.patient.first_name)
                    : "")
                : "";
          },
        },
        Услуга: {
          field: "order_service",
          callback: (value) => {
            return value.service ? value.service.name : "";
          },
        },
        "Дата заказа": {
          field: "order_service",
          callback: (value) => {
            return value.created_at;
          },
        },
        Сумма: {
          field: "order_service",
          callback: (item) => {
            return item.total_price ? item.total_price : 0;
          },
        },
        Бонус: "bonus_amount",
      };
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawer" });
    },
    id(model) {
      let id;
      if (model.order_service) id = model.order_service ? model.order_service.order_id : "";
      if (model.hospitalization_service) id = model.hospitalization_service ? model.hospitalization_service.patient_history_id : "";
      return id;
    },
    type(model) {
      if (model.order_service) return i18n.t('message.ambulatory');
      if (model.hospitalization_service) return i18n.t('message.stationary');
    },
    bonusItemEntity(model) {
      if(model.order_service) return model.order_service;
      if(model.hospitalization_service) return model.hospitalization_service;
    },
    patientName(model) {
      let entity = this.bonusItemEntity(model);
      let patient;
      if (entity.order && entity.order.patient) {
        patient = entity.order.patient;
      }
      if (entity.patient_history && entity.patient_history.patient) {
        patient = entity.patient_history.patient;
      }
      return patient.surname + " " + patient.first_name;
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.dark-mode .filter-wrap__btn.active {
  border-color: #4580eb !important;
  background-color: #4580eb !important;
}

.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;
  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #e4e2e4;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #e4e2e4;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #e4e2e4;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 14px;
  }
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #303030 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>